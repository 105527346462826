import { Route, Routes, useLocation } from "react-router-dom";

import "./App.scss";
import { ParallaxProvider } from "react-scroll-parallax";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import WebContextProvider, { webContext } from "./webContext/WebContext";
import Blogs from "./pages/blogs/Blogs";
import CaseStudies from "./pages/caseStudies/CaseStudies";
import CaseStudyDetail from "./pages/caseStudyDetail/CaseStudyDetail";
import BlogDetails from "./pages/blogDetails/BlogDetails";
import Services from "./pages/services/Services";
import Clients from "./pages/clients/Clients";
import { useContext, useEffect } from "react";
import ErrorPage from "./pages/errorPage/ErrorPage";
import ServiceDetail from "./servicesPages/serviceDetail/ServiceDetail";

function App() {
  const location = useLocation();

  const { startLoader, closeLoader } = useContext(webContext);

  useEffect(() => {
    startLoader();

    setTimeout(() => {
      closeLoader();
    }, 2000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <ParallaxProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:slug" element={<BlogDetails />} />
        <Route path="/casestudies" element={<CaseStudies />} />
        <Route path="/casestudies/:id" element={<CaseStudyDetail />} />
        <Route path="/services" element={<Services />} />
        <Route path="/industry/:slug" element={<ServiceDetail />} />
        <Route path="/services/:slug" element={<ServiceDetail />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
    </ParallaxProvider>
  );
}

export default App;
