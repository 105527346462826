import "./BlogLanding.scss";
import { images } from "../../../utils/images";
import { baseUrl } from "../../../utils/apiData";
import { useNavigate } from "react-router-dom";

const BlogLanding = ({ blogList }) => {
  const navigate = useNavigate();


  // const [readTime, setReadTime] = useState(0);

  // function getTextFromHTML(htmlString) {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(htmlString, "text/html");
  //   return doc.body.textContent || "";
  // }

  // function countWords(text) {
  //   return text.trim().split(/\s+/).length;
  // }

  // function calculateReadTime(wordCount) {
  //   const wordsPerMinute = 200;
  //   const minutes = Math.ceil(wordCount / wordsPerMinute);
  //   return minutes;
  // }

  // useEffect(() => {
  //   const textContent = getTextFromHTML(data?.content);
  //   const wordCount = countWords(textContent);
  //   const estimatedReadTime = calculateReadTime(wordCount);
  //   setReadTime(estimatedReadTime);
  // }, [blogList]);





  return (
    <section className="blog_landing p_top p_b">
      <div className="content_wrap">
        <h2>Stay In the Loop</h2>
        <div className="bi_grid p_top">
          {blogList?.slice(0, 2)?.map((data, j) => {
            return (
              <div
                className="blog_card"
                key={j}
                onClick={() => navigate(`/blogs/${data?.slug}`)}
              >
                <div className="img_wrap">
                  <img
                    src={`${baseUrl}/${data?.image}`}
                    alt="blog"
                    loading="lazy"
                  />
                </div>
                <div className="text_wrap">
                  <h3>{data?.name || "-"}</h3>
                  <p>{data?.subtitle}</p>
                  <div className="category_line">
                    <div className="category">
                      <p className="p_second">Read more</p>
                    </div>
                    <p className="p_second">8 min read</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default BlogLanding;
