import "./Team.scss";
import { images } from "../../../utils/images";
import { FaLinkedinIn } from "react-icons/fa6";
import ScrollAnimationSection from "../../../components/scrollAnimSec/ScrollAnimSec";
import CardAnim from "../../../components/cardAnim/CardAnim";
import { Fragment } from "react";

const Team = () => {
  const teamData = [
    {
      id: 0,
      img: images.zayeem,
      name: "M. Zayeem Raza",
      post: "Product Design",
      linkedin: "https://www.linkedin.com/in/zayeemr/",
    },
    {
      id: 1,
      img: images.viraj,
      name: "Viraj P. Panchal",
      post: "Business Development",
      linkedin: "https://www.linkedin.com/in/viraj-supagrow/",
    },
    {
      id: 2,
      img: images.ankita,
      name: "Ankita M. Subbaiah",
      post: "Operations",
      linkedin:
        "https://www.linkedin.com/in/ankita-subbaiah-muccatira-232a234a/",
    },
    {
      id: 3,
      img: images.uttam,
      name: "Uttam Hansora",
      post: "Product Development",
      linkedin: "",
    },
    {
      id: 4,
      img: images.ashish,
      name: "Ashish Thummar",
      post: "Product Development",
      linkedin: "",
    },
    {
      id: 5,
      img: images.rohit,
      name: "Rohit Jangid",
      post: "Organic Marketing",
      linkedin: "",
    },
  ];

  return (
    <section className="our_team" id="secF">
      <div className="content_wrap p_t p_b">
        <ScrollAnimationSection>
          <h2 className="dark_title">Our Team</h2>
        </ScrollAnimationSection>
        <div className="team_grid">
          {teamData.map((data) => {
            return (
              <Fragment key={data.id}>
            
                  <div className="team_card">
                    <div className="profile_wrap">
                      <img src={data.img} alt="Profile" loading="lazy" />
                    </div>
                    <div className="text">
                      <div className="left">
                        <p>{data.name}</p>
                        <p className="post">{data.post}</p>
                      </div>

                      {data.linkedin && (
                        <a href={data.linkedin} target="_blank">
                          <FaLinkedinIn />
                        </a>
                      )}
                    </div>
                  </div>
            
              </Fragment>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Team;
